//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";
import { displayRuleHelper } from "display-rules";

export default {
  mixins: [fieldBase, liquidable],
  components: {
    LnField: () => import("@/components/ln-form/ln-field.vue"),
  },
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      toggleFields: this.value[0]["isChecked"] || false,
      checkFields: this.value[0]["isChecked"] || false,
    };
  },
  computed: {
    fields() {
      return this.localValue;
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    categoryChecked() {
      this.checkFields = !this.checkFields;
      this.localValue[0]["isChecked"] = this.checkFields;
    },
    displayableFields(fields, submission) {
      return fields.filter((f) =>
        displayRuleHelper(f.display_rules, submission)
      );
    },
  },
};
