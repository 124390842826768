//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormField from "@/models/FormField";
import User from "@/models/User";
import { TEXT_INPUT, CHECKBOX } from "@/models/FormField";
import isMobile from "~/mixins/isMobile.js";

export default {
  mixins: [isMobile],
  head() {
    return {
      title: "Reset Password | LegalNature",
    };
  },
  props: {
    resetSuccess: {
      required: true,
    },
    resetEmail: {
      required: true,
    },
  },
  data() {
    return {
      submission: {
        email: this.resetEmail,
      },
      success: this.resetSuccess,
      error: null,
      loading: false,

      fields: [
        new FormField({
          field_type: TEXT_INPUT,
          name: "email",
          label: "Email",
          placeholder: "john@doe.com",
          validation: { required: true },
          meta: { autocomplete: "email" },
        }),
      ],
    };
  },

  methods: {
    handleSubmit() {
      this.loading = true;
      User.forgotPassword(this.submission.email)
        .then(() => {
          (this.loading = false),
            this.$emit("setResetSuccess", true),
            (this.success = true),
            this.$emit("setResetEmail", this.submission.email);
        })
        .catch((response) => {
          this.error = "Password reset failed, something went wrong!";
        });
    },
    updateData(newValue) {
      this.submission = newValue;
      this.error = null;
    },
  },
};
