//
//
//
//
//
//
//
//
//
//

import foretell from "~/mixins/foretell.js";

export default {
  mixins: [foretell],

  mounted() {
    let pagesToBeExcluded = [
      "categories-parent_slug-product_slug",
      "index",
      "business-formation-index",
      "business-formation-llc",
      "business",
      "personal-affairs",
      "real-estate",
      "categories-parent_slug",
      "registered-agent",
      "compliance-guard",
      "plus",
      "business-formation-entity_type",
    ];
    if (!pagesToBeExcluded.includes(this.$route.name))
      this.createOrLoadUserWithForetellEvent("lnd");

    document.body.classList.remove("modal-active");
  },

  async created() {},
};
