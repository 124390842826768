//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import liquidable from "@/mixins/liquidable";
import Order from "@/models/Order";
import Document from "@/models/Document";

export default {
  mixins: [liquidable],
  name: "ln-field",
  props: {
    fieldIndex: {
      required: false,
    },
    field: {
      required: true,
    },
    disabled: {
      required: false,
    },
    value: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    localValue: {
      handler(newValue) {
        if (this.field.update_company) {
          Order.update({
            where: this.$route.params.orderId,
            data: {
              update_company_field: this.field.update_company,
            },
          });
          Document.update({
            where: this.$route.params.documentId,
            data: {
              update_company_field: this.field.update_company,
            },
          });
        }
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
  computed: {
    isDisabled() {
      if (this.field.disabled || this.disabled) return true;
      else return false;
    },
    widthClass() {
      if (this.field.width)
        if (this.field.width === 4) return "tw-col-span-6 sm:tw-col-span-2";
        else if (this.field.width === 6)
          return "tw-col-span-6 sm:tw-col-span-3";
        else if (this.field.width === 12) return "tw-col-span-6";
      return "tw-col-span-6";
    },
  },
};
