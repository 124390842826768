//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      required: false,
      default: "12",
    },
  },
};
