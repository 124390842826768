//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    field: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      company_name: this.submission && this.submission.company_name,
    };
  },
  mounted() {
    document
      .querySelector("#field-company_name-0")
      .addEventListener("input", this.updateCompanyName, { passive: true });
  },
  methods: {
    updateCompanyName(event) {
      this.company_name = event.target.value;
    },
  },
  computed: {
    updated_submission() {
      return {
        ...this.submission,
        company_name: this.company_name,
      };
    },
  },
};
