//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  props: {
    shown: {
      required: true,
      default: false,
    },
    collapseModal: {
      required: false,
      default: false,
    },
    hideOnBackgroundClick: {
      required: false,
      default: true,
    },
  },
  methods: {
    handleCardClick(e) {
      e.stopPropagation();
    },
    handleBackgroundClick(e) {
      if (this.hideOnBackgroundClick) this.$emit("hide");
    },
  },
  watch: {
    shown: {
      handler(newValue, oldValue) {
        if (newValue === true) {
          document.body.classList.add("modal-active");
        } else if (newValue === false) {
          document.body.classList.remove("modal-active");
        }
      },
    },
    collapseModal(newValue) {
      if (newValue === true) {
        document.body.classList.remove("modal-active");
      } else if (newValue === false) {
        document.body.classList.add("modal-active");
      }
    },
  },
};
