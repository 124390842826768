export default {
  data(){
    return{
      // localOptions: [],
      flagHeader: {},
      productsForOptions: [
        {
          alt: "Family & Personal Matters",
          src: require("~/assets/icons/base/menu-personal.svg"),
          label: "Family & Personal Matters",
          value: "individuals",
          link: "/personal-affairs",
        },
        {
          alt: "Business",
          src: require("~/assets/icons/base/menu-bf.svg"),
          label: "Business",
          value: "business",
          link: "/business",
        },
        {
          alt: "Real Estate",
          src: require("~/assets/icons/base/menu-re.svg"),
          label: "Real Estate",
          value: "landlords",
          link: "/real-estate",
        },
        {
          alt: "Estate Planning",
          src: require("~/assets/icons/base/menu-ep.svg"),
          label: "Estate Planning",
          value: "estate planning",
          link: "/estate-planner",
        },
        {
          alt: "all products",
          src: require("~/assets/icons/base/menu-all.svg"),
          label: "All Products",
          value: "all products",
          link: "/products",
        },
      ],
    }
  },
  // created() {
  //   let option = {};

  //   if (!process.server) {
  //       this.flagHeader = {
  //         title: "UK",
  //         isLanguage: true,
  //         src: require("~/assets/icons/base/flag-uk.svg"),
  //         alt: "UK flag",
  //       };

  //       option = {
  //         src: require("~/assets/icons/base/flag-us.svg"),
  //         alt: "US flag",
  //         label: "US",
  //         link: "https://www.legalnature.com",
  //       };
  //     }
  //     this.localOptions.push(option);
  // },
}