//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from "lodash";
import navigationContent from "@/mixins/navigationContent";
import { directive as onClickaway } from "vue-clickaway2";

export default {
  mixins: [navigationContent],
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {};
  },
  computed: {
    isAuthenticated() {
      return (
        !isEmpty(this.$store.state.user.profile) &&
        this.$store.state.user.profile.email
      );
    },
    profile() {
      return this.$store
        .$db()
        .model("profile")
        .query()
        .first();
    },
    isLoggedIn() {
      if (this.profile && this.profile.registered) return true;
      else return false;
    },
  },
  mounted() {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  },
  beforeDestroy() {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
  },
  methods: {},
};
