var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.field.name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"ln-form-radio ln-form-radio-with-options"},[_c('div',{staticClass:"bv-no-focus-ring",class:_vm.getValidationState(validationContext) == false ? 'is-invalid' : '',attrs:{"id":("field-" + (_vm.field.name) + "-" + _vm.fieldIndex),"role":"radiogroup","tabindex":"-1"}},_vm._l((_vm.fieldOptions),function(option,index){return _c('div',{key:index,class:_vm.radioClass(
            index,
            validationContext,
            _vm.localValue.radioButton === option.value
          ) + ' '+ option.class},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue.radioButton),expression:"localValue.radioButton"}],staticClass:"custom-control-input",attrs:{"type":"radio","autocomplete":"off","id":("field-" + (_vm.field.name) + "_BV_option_" + index + "-" + _vm.fieldIndex),"name":((_vm.field.name) + "-" + _vm.fieldIndex + "-" + index)},domProps:{"value":option.value,"checked":_vm._q(_vm.localValue.radioButton,option.value)},on:{"change":function($event){return _vm.$set(_vm.localValue, "radioButton", option.value)}}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",class:option.isFullWidth && option.image ? 'isFullWidthImage' :'',attrs:{"for":("field-" + (_vm.field.name) + "_BV_option_" + index + "-" + _vm.fieldIndex)}},[_c('p',{staticClass:"ln-radio-custom-label-p"},[_c('span',{domProps:{"innerHTML":_vm._s(option.label)}}),_vm._v(" "),(option.helper)?_c('span',{staticClass:"text-muted",domProps:{"innerHTML":_vm._s(_vm.parseLiquidString(option.helper, _vm.submission))}}):_vm._e(),_vm._v(" "),(option.features && option.features.length > 0)?_c('ul',{staticClass:"custom-label-list"},_vm._l((option.features),function(feature){return _c('li',[_vm._v(_vm._s(feature))])}),0):_vm._e(),_vm._v(" "),(option.selectableOptions)?[_c('p',[_vm._v(_vm._s(option.selectableOptions.text))]),_vm._v(" "),_c('ln-form-checkbox',{staticClass:"ln-form-radio-with-options__checkboxes",attrs:{"value":_vm.localValue.checkbox,"field":{
                  name: _vm.field.name + '-option-' + index  + '-checkboxes' ,
                  options: option.selectableOptions.options
                },"validation-context":validationContext},on:{"input":function($event){return _vm.checkboxClicked($event, index)}}})]:_vm._e(),_vm._v(" "),(option.image==='fedex')?_c('span',{staticClass:"fedex-logo"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('~/assets/icons/general/logo-fedex.svg')),expression:"require('~/assets/icons/general/logo-fedex.svg')"}],attrs:{"alt":"icon add"}})]):_vm._e(),_vm._v(" "),(option.isExtraDocFree)?_c('span',{staticClass:"free-extra-doc"},[_c('span',[_vm._v("FREE")]),_vm._v("Included in your subscription")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"custom-label-price-wrapper"},[(option.price)?_c('span',{staticClass:"custom-label-price"},[_vm._v(_vm._s(option.price)+" "),(option.unit)?_c('span',[_vm._v("/"+_vm._s(option.unit))]):_vm._e()]):_vm._e(),_vm._v(" "),(option.savings)?_c('span',{staticClass:"custom-label-savings"},[_vm._v(_vm._s(option.savings))]):_vm._e(),_vm._v(" "),(option.extra_fee)?_c('span',{staticClass:"custom-label-extra-fee"},[_vm._v(_vm._s(option.extra_fee))]):_vm._e()]),_vm._v(" "),(option.tag)?_c('span',{class:option.price ? 'custom-label-tag' : 'custom-label-tag--noprice'},[_vm._v(_vm._s(option.tag))]):_vm._e()],2),_vm._v(" "),(option.isFullWidth && option.image && option.image !=='fedex')?_c('span',{staticClass:"fw-field-image"},[_c('img',{attrs:{"src":require('~/assets/icons/form/'+ option.image+'.svg')}})]):_vm._e()])])}),0),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.getValidationState(validationContext) === false),expression:"getValidationState(validationContext) === false"}],staticClass:"tw-text-coral tw-mt-2",attrs:{"id":("field-" + (_vm.field.name) + "-feedback")}},[_vm._v("\n  This field is required\n  ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }