//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    document: {
      required: true,
    },
    query: {
      required: true,
    },
  },
  computed: {
    newTitle() {
      const startIndex = this.document.title
        .toLowerCase()
        .indexOf(this.query.toLowerCase());
      if (startIndex !== -1) {
        const endIndex = startIndex + this.query.length;
        const modifiedTitle = this.document.title;
        const segments = [
          modifiedTitle.slice(0, startIndex),
          modifiedTitle.slice(startIndex, endIndex),
          modifiedTitle.slice(endIndex, modifiedTitle.length),
        ];
        return segments[0] + "<span>" + segments[1] + "</span>" + segments[2];
      }
      return this.document.title;
    },
  },
};
