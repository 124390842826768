//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";
import { stateNameByAbbreviation } from "@/src/states";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value ? this.value : null,
    };
  },
  computed: {
    companies() {
      return this.$store
        .$db()
        .model("UserCompanies")
        .all();
    },
    visibleCompanies() {
      return this.companies.filter((company) => company.name);
    },
    parsedFieldOptions() {
      return this.field.options.map((option) => {
        return {
          text: option.label,
          value: option.value,
        };
      });
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
  methods: {
    stateName(state) {
      if (state) {
        return stateNameByAbbreviation(state);
      } else return " ";
    },
    radioClass(validationContext, isChecked) {
      let baseClass = `custom-control custom-radio custom-company-radio custom-radio-`;

      if (validationContext.errors && validationContext.errors.length > 0)
        baseClass += " has-error";
      if (isChecked) baseClass += " is-checked";

      if (this.field.meta && this.field.meta.full_width)
        baseClass += " isFullWidth";
      return baseClass;
    },
    selectClass(validationContext) {
      if (validationContext.errors && validationContext.errors.length > 0)
        return " has-error";
    },
  },

  mounted() {
    // Fetch all post records.
    this.$store
      .$db()
      .model("UserCompanies")
      .fetchAll();
  },
};
