//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import countries from "@/src/countries";
export default {
  mixins: [fieldBase],
  components: {
    Multiselect: async () => import("vue-multiselect").then((x) => x.default),
  },
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    disabled: {
      required: false,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      options: countries,
    };
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
};
