//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    visible: {
      required: true,
    },
    loginTitle: {
      required: true,
    },
    loginSubtitle: {
      required: true,
    },
    signupTitle: {
      required: true,
    },
    signupSubtitle: {
      required: true,
    },
    cta: {
      required: true,
    },
    itemType: {
      required: false,
    },
    itemId: {
      required: false,
    },
    showCloseBtn: {
      required: false,
      default: false,
    },
    formType: {
      required: false,
      default: "signup",
    },
  },
  data() {
    return {
      localVisible: this.visible,
      emailExists: false,
      localFormType: this.formType,
      resetSuccess: false,
      resetEmail: "",
    };
  },
  watch: {
    localVisible(newValue) {
      this.$emit("update:visible", newValue);
    },
    visible(newValue) {
      this.localVisible = newValue;
    },
    formType(newValue) {
      this.localFormType = newValue;
    },
  },
  methods: {
    switchToLogin(emailExists) {
      this.emailExists = emailExists;
      this.localFormType = "login";
    },
    setResetEmail(email) {
      this.resetEmail = email;
    },
  },
};
