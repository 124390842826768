//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    result: {
      required: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    query: {
      required: true,
    },
  },
  computed: {
    cta() {
      if (this.result.type === "Product")
        return { title: "Create", variant: "btn btn-orange large" };
      else if (this.result.type === "Service")
        return { title: "Get Started", variant: "btn btn-orange large" };
      else if (this.result.type === "Guide")
        return { title: "Read", variant: "btn btn-grey large" };
    },
    newTitle() {
      const startIndex = this.result.title
        .toLowerCase()
        .indexOf(this.query.toLowerCase());
      if (startIndex !== -1) {
        const endIndex = startIndex + this.query.length;
        const modifiedTitle = this.result.title;
        const segments = [
          modifiedTitle.slice(0, startIndex),
          modifiedTitle.slice(startIndex, endIndex),
          modifiedTitle.slice(endIndex, modifiedTitle.length),
        ];
        return segments[0] + "<span>" + segments[1] + "</span>" + segments[2];
      }
      return this.result.title;
    },
  },
};
