import { render, staticRenderFns } from "./mobile-overlay.vue?vue&type=template&id=24f51606&"
import script from "./mobile-overlay.vue?vue&type=script&lang=js&"
export * from "./mobile-overlay.vue?vue&type=script&lang=js&"
import style0 from "./mobile-overlay.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArrowRight: require('/usr/src/app/components/icons/arrow-right.vue').default})
