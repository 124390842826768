//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import API from "~/api/api";

export default {
  components: {
    API,
  },
  props: {
    ID: {
      type: String,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Number,
      default: -1,
    },
    allResultsUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return { query: "" };
  },
  methods: {
    focusInput() {
      this.$nextTick(() => {
        this.$refs.searchBar.focus();
      });
    },
    handleCloseSearch() {
      this.$emit("handleCloseSearch");
    },
    handleShowSearch() {
      this.$emit("handleShowSearch");
      this.focusInput();
    },
    setSearchResults() {
      this.$emit("setSearchResults", this.query);
    },
    cleanQuery() {
      this.$emit("cleanQuery");
      this.query = "";
    },
    nextItem(e) {
      this.$emit("nextItem", e);
    },
  },
};
