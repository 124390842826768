export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    if (!process.server) {
      window.addEventListener("resize", this.handleResize, { passive: true });
      this.handleResize();
    }
  },
  destroyed() {
    if (!process.server)
      window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  computed: {
    isMobile() {
      if (this.window.width < 992) {
        return true;
      } else if (this.window.width > 992) return false;
    },
  },
};
