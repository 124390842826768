//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";
import { cloneDeep } from "lodash";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      fieldOptions: cloneDeep(this.field.options),
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
    field: {
      handler(newValue, oldValue) {},
      deep: true,
    },
    submission: {
      handler(newValue, oldValue) {},
      deep: true,
    },
    validationContext: {
      handler(newValue, oldValue) {},
      deep: true,
    },
  },
  created() {},
  mounted() {},
  updated() {},
  beforeUpdate() {},
  methods: {
    radioClass(index, validationContext, isChecked) {
      let baseClass = `custom-control custom-radio custom-radio-${index}`;

      if (validationContext.errors && validationContext.errors.length > 0)
        baseClass += " has-error";

      if (isChecked) baseClass += " is-checked";

      if (this.field.meta && this.field.meta.full_width)
        baseClass += " isFullWidth";
      if (this.field.options[index].isFullWidth) baseClass += " isFullWidth";

      return baseClass;
    },

    radioButtonClicked(e) {
      this.localValue = {
        radioButton: e.target.value,
        checkbox: [],
      };
      this.$emit("input", this.localValue);
    },
    checkboxClicked(value, questionIndex) {
      this.localValue = {
        radioButton: this.field.options[questionIndex].value,
        checkbox: value,
      };
      this.$emit("input", this.localValue);
    },
  },
};
