//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";

export default {
  mixins: [fieldBase],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
  },
  data() {
    return { localValue: this.value };
  },
};
