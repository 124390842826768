import API from "~/api/api";

export default {
  components: {
    API,
  },
  data() {
    return {
      showSearch: false,
      records: [],
      count: 0,
      searchLoading: false,
      error: null,
      waitABit: null,
      query: null,
      currentItem: -1,
    };
  },
  props: {},
  computed: {
    shortRecords() {
      if(this.records.length > 4)
      return this.records.slice(0, 3);
      else return this.records;
    },
    allResultsUrl() {
      if (this.query) {
        return `/search?query=${this.query}`;
      } else {
        return "/search";
      }
    },

    allResultsUrlAccount() {
      if (this.query) {
        return `/account/search?query=${this.query}`;
      } else {
        return "/account/search";
      }
    },
    isSearchPage() {
      if (this.$router.currentRoute.path === "/search") return true;
      else return false;
    },
  },
  methods: {
    resultHover(index) {
      this.currentItem = index;
    },
    handleCloseSearch() {
      this.showSearch = false;
      document.body.style = "overflow: scroll";
    },
    handleShowSearch() {
      this.showSearch = true;
      if (!document.body.style) document.body.style = {};
      document.body.style = "overflow: hidden";

    },

    cleanQuery() {
      this.query = "";
      this.setSearchResults(this.query);
    },
    setSearchResults(serverQuery, type) {
      this.searchLoading = true;
      this.query = serverQuery;
      const self = this

      if(this.waitABit){
        clearTimeout(this.waitABit)
        this.waitABit = null
      }
      this.waitABit = setTimeout(() => {
        API.search
        .get(self.$axios, {
          query: serverQuery,
          page: self.page,    
          type: type,
        })
        .then(({ records, count, page }) => {
          self.records = records;
          self.count = count;
          self.page = page;
          self.waitABit = null;
          self.searchLoading = false;

        })
        .catch((e) => console.error(e));
      }, 300)
    },
    nextItem(e) {
      if (!this.currentItem) this.currentItem = 0;
      if (e.keyCode == 38 && this.currentItem > -1) {
        this.currentItem--;
        e.preventDefault();
        return false;
      } else if (e.keyCode == 40 && this.currentItem < 3) {
        this.currentItem++;
        e.stopPropagation();
      } else if (
        e.keyCode == 13 &&
        this.currentItem != -1 &&
        this.shortRecords[this.currentItem].link
      ) {
        this.$router.push(this.shortRecords[this.currentItem].link);
        this.$emit("closeSearch");
      } else if (e.keyCode == 13 && this.currentItem == -1) {
        this.$router.push(this.allResultsUrl);
        this.$emit("closeSearch");
      } else if (e.keyCode == 38) {
        e.preventDefault();
      }
    },
  },
};
