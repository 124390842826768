//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import "vue2-datepicker/index.css";

let parseDate = (date) => {
  if (date) {
    return new Date(Date.parse(date));
  }
};
export default {
  mixins: [fieldBase],
  components: {
    DatePicker: async () => import("vue2-datepicker").then((x) => x.default),
  },
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      key: 1,
    };
  },
  watch: {
    field: {
      handler(newValue) {
        if (this.localValue != this.value) {
          this.key = +this.key;
          this.localValue = this.value;
        }
      },
    },
  },
  methods: {
    onContext(ctx) {
      if (ctx.selectedFormatted != "No date selected") {
        // The date formatted in the locale, or the `label-no-date-selected` string
        this.localValue = ctx.selectedFormatted;

        this.$emit("input", this.localValue);
      }
    },
    updateDatePicker() {
      this.$emit("input", this.localValue);
    },
  },
};
