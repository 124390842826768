import { cloneDeep } from "lodash";

const STARTER_VALUES = {
  checkbox: [],
  multi_check_long_alt: [],
  multiplier: [""],
  multiplier_alt: [""],
  multiplier_long_alt: [""],
  multiplier_v2: [""],
  multiplier_long: [""],
  multiplier_area_long: [""],
  multiplier_left: [""],
  select_multiplier_with_pricing: [""],
  nested_multiplier: [],
  nested_multiplier_v2: [],
  date_calculator: [""],
  nested_fields: {},
  checkbox_group: [],
  radio_with_options: {
    radioButton: "",
    checkbox: [],
  },
  country_multi_select: [],
  bf_packages: [],

};

const starterValue = (field) => {
  if (field.fields && field.fields.length > 0) {
    let _starterValue = {};
    field.fields.forEach((subfield) => {
      if (subfield.name && !subfield.isDisplayOnly) {
        // check if the field has the correct starter value, if not set it.
        _starterValue[subfield.name] = starterValue(subfield);
      }
    });
    return [_starterValue];
  } else {
    return cloneDeep(STARTER_VALUES[field.field_type]) || field.default || "";
  }
};

export default starterValue;
