//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import search from "@/mixins/search";
import navigationContent from "@/mixins/navigationContent";
import { directive as onClickaway } from "vue-clickaway2";

export default {
  created() {
    if (process.browser)
      window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.$nuxt.$on("activateSearch", () => {
      this.handleShowSearch();
    });
  },
  destroyed() {
    if (process.browser)
      window.removeEventListener("scroll", this.handleScroll);
  },
  directives: {
    onClickaway: onClickaway,
  },
  mixins: [search, navigationContent],
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleScroll() {
      if (!window) return;
      if (window.scrollY > 100) {
        this.makeNavSticky = true;
      } else this.makeNavSticky = false;
    },
    hideProductsMenu() {
      if (this.productsMenu === true) this.productsMenu = false;
    },
    hideHelpMenu() {
      if (this.helpMenu === true) this.helpMenu = false;
    },
  },
  computed: {
    helpOptions() {
      return [
        {
          label: "Help Guides",
          value: "articles",
          link: "/guides",
        },
        {
          label: "Billing FAQs",
          value: "billing faqs",
          link: "/guides/categories/billing-faqs",
        },
      ];
    },
    profile() {
      return this.$store
        .$db()
        .model("profile")
        .query()
        .first();
    },
    isLoggedIn() {
      if (this.profile && this.profile.registered) return true;
      else return false;
    },
  },
  data() {
    return {
      makeNavSticky: false,
      query: null,
      productsMenu: false,
      helpMenu: false,
    };
  },
};
