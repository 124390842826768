//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { displayRuleHelper } from "display-rules";
import FormModelGenerator from "@/src/form-helpers/form-model-generator";
import { stateNameByValue } from "@/src/states";

export default {
  props: {
    submission: {
      required: true,
    },
    fields: {
      required: true,
    },
    remakeFromServerTimestamp: {
      required: false,
    },
  },
  data() {
    let localData = new FormModelGenerator(this.fields, this.submission)
      .generateData;
    if (localData != this.submission) {
      this.$emit("updateData", localData);
    }
    return {
      localData,
    };
  },
  computed: {
    visibleFields() {
      if (this.localData) {
        // add in state to helpers
        let dataWithState = { ...this.localData };
        let currentState = stateNameByValue(dataWithState.state_id);
        if (!dataWithState.state || currentState != dataWithState.state) {
          dataWithState.state = currentState;
        }
        return this.fields.filter((field) =>
          displayRuleHelper(field.display_rules, dataWithState)
        );
      } else {
        return this.fields.filter((field) =>
          displayRuleHelper(field.display_rules, this.submission)
        );
      }
    },
  },
  watch: {
    remakeFromServerTimestamp: {
      handler() {
        this.localData = new FormModelGenerator(
          this.fields,
          this.submission
        ).generateData;
      },
    },
    localData: {
      handler(newValue) {
        this.visibleFields
          .filter((field) => field.isRadioWithOptions)
          .forEach((field) => {
            newValue[`${field.name}_checkbox`] = newValue[field.name]
              ? newValue[field.name].checkbox
              : null;
            newValue[`${field.name}_radio`] = newValue[field.name]
              ? newValue[field.name].radioButton
              : null;
          });

        this.$emit("updateData", newValue);
      },
      deep: true,
    },
  },
  methods: {
    onSubmit(evt) {
      this.$emit("nextPage");
    },
    submit() {
      this.$refs.form.submit();
    },
  },
};
