//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import search from "@/mixins/search";

export default {
  mixins: [search],
  data() {
    return {
      openModal: false,
      isMobile: true,
    };
  },
  methods: {
    emitHandleDashboard() {
      this.$emit("handleDashboard");
    },
    toggleMenu() {
      this.openModal = !this.openModal;

      if (this.openModal === true) {
        document.body.style = "overflow: hidden";
      } else document.body.style = "overflow: scroll";
    },
  },
  watch: {
    $route(to, from) {
      this.openModal = false;
    },
  },
  created() {
    this.$nuxt.$on("activateMobileSearch", () => {
      this.handleShowSearch();
    });
  },
};
