//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VueMaskDirective } from "v-mask";
import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    submission: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
  },
  data() {
    return { localValue: this.value };
  },
  watch: {
    field: {
      handler(newValue) {
        if (this.localValue != this.value) {
          this.localValue = this.value;
        }
      },
    },
  },
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      VueMaskDirective.bind(el, binding);
    },
  },
};
