//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from "lodash";
import FormModelGenerator from "@/src/form-helpers/form-model-generator";
import { displayRuleHelper } from "display-rules";
import FormField from "@/models/FormField";

export default {
  components: {
    LnField: () => import("@/components/ln-form/ln-field.vue"),
  },
  props: {
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      newValue: new FormModelGenerator(this.field.fields, {}).generateData,
    };
  },
  methods: {
    addEntry() {
      this.localValue = cloneDeep(this.localValue).concat(
        cloneDeep(this.newValue)
      );
    },
    removeEntry(index) {
      this.localValue = this.localValue.filter(
        (value, localIndex) => index !== localIndex
      );
    },
    displayableFields(fields, submission) {
      return fields.filter((f) =>
        displayRuleHelper(f.display_rules, submission)
      );
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
  computed: {
    fields() {
      return this.localValue;
    },
  },
};
