import { render, staticRenderFns } from "./packages-preview-boxes.vue?vue&type=template&id=67ec4226&scoped=true&"
import script from "./packages-preview-boxes.vue?vue&type=script&lang=js&"
export * from "./packages-preview-boxes.vue?vue&type=script&lang=js&"
import style0 from "./packages-preview-boxes.vue?vue&type=style&index=0&id=67ec4226&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ec4226",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LnCustomButton: require('/usr/src/app/components/ln-custom-button.vue').default})
