//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      toggleCheckmarks: false,
    };
  },
  computed: {
    parsedFieldOptions() {
      return this.field.options.map((option) => {
        return {
          text: option.label,
          value: option.value,
        };
      });
    },
    getSelection() {
      if (this.localValue.length === 0) return "none";
      if (this.localValue.length === this.parsedFieldOptions.length)
        return "all";
      return "partial";
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.toggleCheckmarks = this.localValue && this.localValue.length > 0;
  },
  methods: {
    categoryChecked(name) {
      if (this.toggleCheckmarks == false) this.toggleCheckmarks = true;
      if (this.localValue.length > 0) this.localValue = [];
      else this.localValue = this.parsedFieldOptions.map((i) => i.value);
    },
  },
};
