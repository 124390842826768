//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Order from "@/models/Order";
import fieldBase from "@/mixins/field-base";

export default {
  mixins: [fieldBase],
  props: {
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    validationContext: {
      required: true,
    },
    fieldIndex: {
      required: false,
      default: "",
    },
  },
  data() {
    return { localValue: this.value, isReplacingFile: false, fileError: null };
  },
  computed: {
    showUploadBtn() {
      if (this.isReplacingFile) return true;
      if (this.localValue && this.localValue.id) return false;
      return true;
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    field: {
      handler(newValue) {
        if (this.localValue != this.value) {
          this.localValue = this.value;
        }
      },
    },
  },
  methods: {
    handleFileError(error) {
      this.fileError = error.message;
    },
    deleteFile() {
      this.isReplacingFile = true;
      Order.deleteFile(this.$route.params.orderId, this.localValue.id);
      this.localValue = null;
    },
    newImageSelected(imageData) {
      if (imageData) {
        this.fileError = null;
        this.handleSubmit({ file: imageData.blob, fileName: imageData.name });
      }
    },
    async handleSubmit(file) {
      let response = await Order.uploadFile(this.$route.params.orderId, file);
      this.localValue = response;
      this.isReplacingFile = false;
    },
  },
};
