//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";

export default {
  mixins: [fieldBase],
  props: {
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    validationContext: {
      required: true,
    },
    fieldIndex: {
      required: false,
      default: "",
    },
  },
  data() {
    return { localValue: this.value };
  },
  computed: {
    parsedFieldOptions() {
      return [
        {
          value: "",
          disabled: true,
          text: this.field.placeholder || "Select an option",
        },
      ].concat(
        this.field.options.map((option) => {
          return {
            text: option.label,
            value: option.value.toString(),
            disabled: option.disabled,
          };
        })
      );
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    field: {
      handler(newValue) {
        if (this.localValue != this.value) {
          this.localValue = this.value;
        }
      },
    },
  },
};
