//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      isExtraDocFree: false,
    };
  },

  computed: {
    parsedFieldOptions() {
      return this.field.options.map((option) => {
        return {
          text: option.label,
          value: option.value,
        };
      });
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    field: {
      handler(newValue) {
        if (this.localValue != this.value) {
          this.localValue = this.value;
        }
      },
    },
  },
  methods: {
    radioClass(option, index, validationContext, isChecked) {
      let baseClass = `custom-control custom-radio custom-radio-${index}`;

      if (option.is_highlightedOption) baseClass += " highlighted-option";

      if (validationContext.errors && validationContext.errors.length > 0)
        baseClass += " has-error";

      if (isChecked) baseClass += " is-checked";

      if (this.field.meta && this.field.meta.full_width)
        baseClass += " isFullWidth";
      if (this.field.options[index].isFullWidth) baseClass += " isFullWidth";
      return baseClass;
    },
  },
};
