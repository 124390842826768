//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VueMaskDirective } from "v-mask";
import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    submission: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    field: {
      handler(newValue) {
        if (this.field.raw_value) {
          if (this.localValue.replace(/[$,]/g, "") != this.value) {
            this.localValue = this.value;
          }
        } else if (this.localValue != this.value) {
          this.localValue = this.value;
        }
      },
    },
    localValue: {
      handler(newValue) {
        if (this.field.pattern === "money") {
          if (this.localValue != this.value)
            if (this.field.raw_value) {
              let rawValue = newValue.replace(/[$,]/g, "");
              this.$emit("input", rawValue);
            } else this.$emit("input", newValue);
        }
      },
      deep: true,
    },
  },

  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      VueMaskDirective.bind(el, binding);
    },
  },
};
