//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isBefore } from "date-fns";
import terms from "@/mixins/terms";

export default {
  mixins: [terms],

  props: {
    includeFixed: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      env: "en",
      showFixed: false,
      is_auth_modal: false,
      isLogin: false,
      headerLocalFormType: "signup",
      newTermsModal: false,
    };
  },
  methods: {
    authSuccess() {
      this.is_auth_modal = false;
      if (this.userAcceptedTerms(this.profile, this.isAuthenticated))
        this.$router.push("/account");
      else this.newTermsModal = true;
    },
    acceptTermsSuccess() {
      this.$router.push("/account");
      this.newTermsModal = false;
    },
    handleDashboard() {
      if (this.isAuthenticated) {
        this.$router.push("/account");
      } else {
        this.headerLocalFormType = "login";
        this.showAuthModal();
      }
    },
    visibilityChanged(isVisible, entry) {
      this.showFixed = !isVisible;
    },
    showAuthModal() {
      this.is_auth_modal = true;
    },
    hideAuthModal() {
      this.is_auth_modal = false;
    },
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue === false) if (this.showSignUp) this.showAuthModal();
    },
  },
  computed: {
    profile() {
      return this.$store
        .$db()
        .model("profile")
        .query()
        .first();
    },
    isProfileLoaded() {
      return this.loadRequest ? this.loadRequest.isLoaded : false;
    },
    loadRequest() {
      return this.$store
        .$db()
        .model("requests")
        .find("profile");
    },
    showSignUp() {
      if (this.$route.query.signup) return true;
    },
    shouldLoadUser() {
      return this.$store.state.user.loading;
    },
    isAuthenticated() {
      if (this.profile) return this.profile.registered;
      else return false;
    },
  },
  mounted() {
    this.$store
      .$db()
      .model("profile")
      .getProfile();
  },
};
