import { render, staticRenderFns } from "./default.vue?vue&type=template&id=905c85ec&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeader: require('/usr/src/app/components/base-header/base-header.vue').default,BaseFooter: require('/usr/src/app/components/base-footer/base-footer.vue').default})
