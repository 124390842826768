//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
    submission: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      checkFields: this.value !== "" ? true : false,
      showDescription: false,
      illustrations: {
        addons: require("~/assets/icons/form/illustration-addons.svg"),
        businessCallReverse: require("~/assets/icons/form/illustration-business-call-reverse.svg"),
        corporateKitImg: require("~/assets/icons/form/corporate-kit.png"),
        shippingFull: require("~/assets/icons/form/illustration-shipping-full.svg"),
        fileSearch: require("~/assets/icons/form/illustration-file-search.svg"),
      },
    };
  },

  computed: {
    parsedFieldOptions() {
      return this.field.options.map((option) => {
        return {
          text: option.label,
          value: option.value,
        };
      });
    },
    addonDescription() {
      // if (this.field.helper_text.length > 55)
      //   if (!this.showDescription)
      //     return this.field.helper_text.substr(0, 55) + "...";
      //   else return this.field.helper_text;
      // else
      return this.field.helper_text;
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        if (newValue !== "") this.checkFields = true;
        this.$emit("input", newValue);
      },
      deep: true,
    },
    field: {
      handler(newValue) {
        if (this.localValue != this.value) {
          this.localValue = this.value;
        }
      },
    },
  },
  methods: {
    categoryChecked() {
      this.checkFields = !this.checkFields;
      if (!this.field.options) {
        if (this.checkFields === true) this.localValue = "Yes";
        else this.localValue = "";
      } else {
        if (this.checkFields === true)
          this.localValue = this.field.options[0].value;
        else this.localValue = "";
      }
    },
    radioClass(option, index, validationContext, isChecked) {
      let baseClass = `custom-control custom-radio custom-radio-${index}`;

      if (option.is_highlightedOption) baseClass += " highlighted-option";

      if (validationContext.errors && validationContext.errors.length > 0)
        baseClass += " has-error";

      if (isChecked) baseClass += " is-checked";

      if (this.field.meta && this.field.meta.full_width)
        baseClass += " isFullWidth";
      if (this.field.options[index].isFullWidth) baseClass += " isFullWidth";
      return baseClass;
    },
  },
};
