//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import liquidable from "@/mixins/liquidable";
import { cloneDeep } from "lodash";

export default {
  mixins: [fieldBase, liquidable],
  props: {
    value: {
      required: true,
    },
    isTextArea: {
      default: false,
    },
    isSelect: {
      default: false,
    },
    placeholder: {
      default: "",
    },
    field: {
      default: "",
    },
    validationContext: {
      required: true,
    },
    submission: {
      required: false,
      default: "",
    },
  },
  data() {
    return { localValue: this.value };
  },
  computed: {
    parsedFieldOptions() {
      return [
        {
          value: "",
          disabled: true,
          text: this.field.placeholder || "Please select an option",
        },
      ].concat(
        this.field.options.map((option) => {
          return {
            text: option.label,
            value: option.value.toString(),
          };
        })
      );
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
