import { isBefore } from "date-fns";

export default {

    computed:{
        newTermsDate(){
            return '2024-09-19T06:35:12-07:00'
        },
    },
    methods:{
        userAcceptedTerms(profile, isAuthenticated) {
            if (profile && isAuthenticated)
              if (!profile.terms_accepted_at) return false;
              else if (
                isBefore(
                  new Date("2024-09-16T09:47:12-07:00"),
                  new Date(profile.terms_accepted_at)
                )
              ) {
                return true;
              } else {
                return false;
              }
            return false;
          },
        }
}