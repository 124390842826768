import starterValue from "./field-starter-value-generator.js";
import { cloneDeep } from "lodash";

export default class FormModelGenerator {
  constructor(fields, data) {
    this.fields = fields;
    this.data = cloneDeep(data);
  }

  // give defaults for fields
  get generateData() {
    this.fields.forEach((field) => {
      if (field.name && !field.isDisplayOnly) {
        // check if the field has the correct starter value, if not set it.
        if (
          this.data[field.name] &&
          typeof this.data[field.name] == typeof starterValue(field)
        ) {
          this.data[field.name] = this.data[field.name];
        } else {
          this.data[field.name] = starterValue(field);
        }
      }
    });

    return this.data;
  }
}
