import { Liquid } from 'liquidjs'

const engine = new Liquid()

export default {
  methods: {
    parseLiquidString (string, data) {
      if (!string) return ''
      return engine.parseAndRenderSync(string, data)
    }
  }
} 