//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormField from "@/models/FormField";
import User from "@/models/User";
import { TEXT_INPUT, CHECKBOX } from "@/models/FormField";
import isMobile from "~/mixins/isMobile.js";

export default {
  mixins: [isMobile],
  props: {
    title: {
      required: true,
    },
    subtitle: {
      required: true,
    },
    cta: {
      required: true,
    },
    emailExists: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      submission: {
        email: "",
        password: "",
      },
      error: null,
      fields: [
        new FormField({
          field_type: TEXT_INPUT,
          name: "email",
          label: "Email",
          placeholder: "john@doe.com",
          validation: { required: true },
          meta: { autocomplete: "email" },
        }),
        new FormField({
          field_type: TEXT_INPUT,
          name: "password",
          label: "Password",
          placeholder: "Password",
          validation: { required: true },
          meta: { autocomplete: "password" },
        }),
      ],
    };
  },

  methods: {
    handleSubmit() {
      User.login(this.submission)
        .then(() => {
          this.$emit("success");
        })
        .catch(({ response }) => {
          if (response.status == 303 && response.data.message) {
            this.error = response.data.message;
          } else {
            this.error = "Login failed, something went wrong!";
          }
        });
    },
    updateData(newValue) {
      this.submission = newValue;
      this.error = null;
    },
  },
};
