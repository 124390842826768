import { render, staticRenderFns } from "./search-bar.vue?vue&type=template&id=0d1e1370&"
import script from "./search-bar.vue?vue&type=script&lang=js&"
export * from "./search-bar.vue?vue&type=script&lang=js&"
import style0 from "./search-bar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchIcon: require('/usr/src/app/components/icons/search-icon.vue').default})
