//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";

export default {
  mixins: [fieldBase],
  props: {
    fieldIndex: {
      required: false,
      default: "",
    },
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    validationContext: {
      required: true,
    },
  },
  data() {
    return { localValue: this.value };
  },
  created() {},
  updated() {},
  computed: {
    parsedFieldOptions() {
      return this.field.options.map((option) => {
        return {
          text: option.label,
          value: option.value,
        };
      });
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
    localValue: {
      handler(newValue) {
        if (this.localValue != this.value) this.$emit("input", newValue);
      },
      deep: true,
    },
  },
  methods: {
    checkboxClass(index, validationContext, isChecked) {
      let baseClass = `custom-control custom-checkbox custom-checkbox-${index}`;

      if (this.field.is_bordered) baseClass += " bordered-checkbox";

      if (!this.field.is_bordered) baseClass += " tw-mr-3";

      if (validationContext.errors && validationContext.errors.length > 0)
        baseClass += " has-error";

      if (isChecked) baseClass += " is-checked";

      if (this.field.meta && this.field.meta.full_width)
        baseClass += " isFullWidth";

      if (this.field.options[index].isFullWidth) baseClass += " isFullWidth";
      return baseClass;
    },
  },
};
