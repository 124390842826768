//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    category: {
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    query: {
      required: true,
    },
  },
  computed: {
    showCategoryProducts() {
      if (this.isMobile === true) {
        return 3;
      }
      return 8;
    },
    newTitle() {
      const startIndex = this.category.title
        .toLowerCase()
        .indexOf(this.query.toLowerCase());
      if (startIndex !== -1) {
        const endIndex = startIndex + this.query.length;
        const modifiedTitle = this.category.title;
        const segments = [
          modifiedTitle.slice(0, startIndex),
          modifiedTitle.slice(startIndex, endIndex),
          modifiedTitle.slice(endIndex, modifiedTitle.length),
        ];
        return segments[0] + "<span>" + segments[1] + "</span>" + segments[2];
      }
      return this.category.title;
    },
  },
};
