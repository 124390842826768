













export default {
  props: {
    removed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      IMAGE_MAX_WIDTH: 3200,
    }
  },
  watch: {
    removed (newValue) {
      if (newValue === true) {
        this.onRemoveImage()
      }
    }
  },

  methods: {
    onChange (event) {
      var file = event.target.files[0]

      if (file.type && (file.type.match(/image.*/) || file.type === 'image/heic')) {
        this.imageSrc = URL.createObjectURL(file)
        this.readImageContent(file)
        this.$refs.fileInput.value = null
      } else {
        this.$emit('error', {
          type: 'invalid_format',
          message: 'Selected file format is not accepted. Please select and image file format (JPG, PNG, HEIC)'
        })
      }
    },
    onRemoveImage () {
      this.$refs.fileInput.value = null
    },

    openNativePicker () {
      this.$refs.fileInput.click()
    },

    readImageContent (file) {
      const reader = new FileReader()
      reader.onload = (data) => {
        this.onLoadFileRead(data, file.name)
      }
      reader.readAsDataURL(file)
    },

    onLoadFileRead (readerEvent, fileName) {
      var image = new Image()
      image.onload = this.resizeImageInCanvas.bind(this, image)
      image.src = readerEvent.target.result
      image.name = fileName

    },

    async resizeImageInCanvas (image) {
      const canvas = document.createElement('canvas')
      let imageToProcess = image;
      if (image.type === 'image/heic') {
        const convertedBlob = await this.$heic2any({
          blob: image,
          toType: "image/jpeg",
          quality: 0.8 // Adjust quality as needed
        });
       imageToProcess = new File([convertedBlob], image.name, {type: 'image/jpeg'});
       }

      const imageDimensions = this.calculateImageDimensions(imageToProcess)

      canvas.width = imageDimensions.width
      canvas.height = imageDimensions.height
      canvas.getContext('2d').drawImage(imageToProcess, 0, 0, imageDimensions.width, imageDimensions.height)
      const imageBlob = this.dataURLToBlob(canvas.toDataURL('image/jpeg'))
      const imageSrc = URL.createObjectURL(imageBlob)
      const imageName = imageToProcess.name

      this.$emit('new-image', {
        blob: imageBlob,
        src: imageSrc,
        name: imageName
      })

    },

    calculateImageDimensions (image) {
      const maxSize = this.IMAGE_MAX_WIDTH
      let width = image.width
      let height = image.height

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width
          width = maxSize
        }
      } else if (height > maxSize) {
        width *= maxSize / height
        height = maxSize
      }

      return {
        width,
        height
      }
    },

    dataURLToBlob (dataURL) {
      let parts
      let contentType
      let raw
      const BASE64_MARKER = ';base64,'

      if (dataURL.indexOf(BASE64_MARKER) === -1) {
        parts = dataURL.split(',')
        contentType = parts[0].split(':')[1]
        raw = parts[1]

        return new Blob(
          [raw],
          {
            type: contentType
          })
      }

      parts = dataURL.split(BASE64_MARKER)
      contentType = parts[0].split(':')[1]
      raw = window.atob(parts[1])
      const rawLength = raw.length

      var uInt8Array = new Uint8Array(rawLength)

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }

      return new Blob(
        [uInt8Array],
        {
          type: contentType
        })
    }
  }
}
