import { render, staticRenderFns } from "./search-modal.vue?vue&type=template&id=03ca10b8&"
import script from "./search-modal.vue?vue&type=script&lang=js&"
export * from "./search-modal.vue?vue&type=script&lang=js&"
import style0 from "./search-modal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryResult: require('/usr/src/app/components/search/result-types/category-result.vue').default,DocumentResult: require('/usr/src/app/components/search/result-types/document-result.vue').default,CompanyResult: require('/usr/src/app/components/search/result-types/company-result.vue').default,ProductResult: require('/usr/src/app/components/search/result-types/product-result.vue').default,Loader: require('/usr/src/app/components/loader/loader.vue').default,IconChevron: require('/usr/src/app/components/icons/icon-chevron.vue').default,LnCustomButton: require('/usr/src/app/components/ln-custom-button.vue').default})
