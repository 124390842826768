//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormField from "@/models/FormField";
import User from "@/models/User";
import { TEXT_INPUT, CHECKBOX } from "@/models/FormField";
import isMobile from "~/mixins/isMobile.js";

export default {
  mixins: [isMobile],
  props: {
    title: {
      required: true,
    },
    subtitle: {
      required: true,
    },
    cta: {
      required: true,
    },
    itemId: {
      required: false,
    },
    itemType: {
      required: false,
    },
  },
  data() {
    return {
      submission: {
        email: "",
        password: "",
        tos: [],
        item_id: this.itemId,
        item_type: this.itemType,
        set_apple_pay: false,
        terms_accepted_at: new Date(),
      },
      error: null,
      emailExists: false,
      fields: [
        new FormField({
          field_type: TEXT_INPUT,
          name: "email",
          label: "Email",
          placeholder: "john@doe.com",
          validation: { required: true },
          meta: { autocomplete: "email" },
        }),
        new FormField({
          field_type: TEXT_INPUT,
          name: "password",
          label: "Create Password",
          placeholder: "Password",
          validation: { required: true },
          meta: { autocomplete: "password" },
        }),
        new FormField({
          field_type: CHECKBOX,
          name: "tos",
          options: [
            {
              label: "I agree to LegalNature's Privacy Policy and Terms of Use",
              value: "Yes",
            },
          ],
          validation: { required: true },
        }),
      ],
    };
  },
  methods: {
    handleSubmit() {
      User.createAccount(this.submission)
        .then(() => this.$emit("success"))
        .catch(({ response }) => {
          if (response.status == 303 && response.data.message) {
            this.$emit("switch", true);
          } else {
            this.error = "Signup failed, something went wrong!";
          }
        });
    },
    updateData(newValue) {
      this.submission = newValue;
      this.error = null;
    },
  },
  mounted() {
    if (window.ApplePaySession) {
      this.submission.set_apple_pay = true;
    }
  },
};
