var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ln-form-input"},[_c('div',{staticClass:"tw-w-full"},[((_vm.field.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.field.mask),expression:"field.mask"}],class:[
        'tw-p-4 tw-block tw-w-full tw-rounded-md tw-border tw-shadow-sm focus:tw-outline-none tw-leading-5',
        _vm.getValidationState(_vm.validationContext) === false
          ? 'tw-border-coral'
          : 'tw-border-silver focus:tw-border-azure' ],attrs:{"placeholder":_vm.field.pattern === 'money' ? '$' : _vm.field.placeholder,"disabled":_vm.disabled,"autocomplete":_vm.field.autocomplete,"id":("field-" + (_vm.field.name) + "-" + _vm.fieldIndex),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"change":[function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}},function($event){return _vm.$emit('input', _vm.localValue)}]}}):((_vm.field.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.field.mask),expression:"field.mask"}],class:[
        'tw-p-4 tw-block tw-w-full tw-rounded-md tw-border tw-shadow-sm focus:tw-outline-none tw-leading-5',
        _vm.getValidationState(_vm.validationContext) === false
          ? 'tw-border-coral'
          : 'tw-border-silver focus:tw-border-azure' ],attrs:{"placeholder":_vm.field.pattern === 'money' ? '$' : _vm.field.placeholder,"disabled":_vm.disabled,"autocomplete":_vm.field.autocomplete,"id":("field-" + (_vm.field.name) + "-" + _vm.fieldIndex),"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"change":[function($event){_vm.localValue=null},function($event){return _vm.$emit('input', _vm.localValue)}]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.field.mask),expression:"field.mask"}],class:[
        'tw-p-4 tw-block tw-w-full tw-rounded-md tw-border tw-shadow-sm focus:tw-outline-none tw-leading-5',
        _vm.getValidationState(_vm.validationContext) === false
          ? 'tw-border-coral'
          : 'tw-border-silver focus:tw-border-azure' ],attrs:{"placeholder":_vm.field.pattern === 'money' ? '$' : _vm.field.placeholder,"disabled":_vm.disabled,"autocomplete":_vm.field.autocomplete,"id":("field-" + (_vm.field.name) + "-" + _vm.fieldIndex),"type":_vm.field.inputType},domProps:{"value":(_vm.localValue)},on:{"change":function($event){return _vm.$emit('input', _vm.localValue)},"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}}),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.getValidationState(_vm.validationContext) === false),expression:"getValidationState(validationContext) === false"}],staticClass:"tw-text-coral tw-mt-2",attrs:{"id":("field-" + (_vm.field.name) + "-feedback")}},[_vm._v("\n      "+_vm._s(_vm.parseLiquidString(_vm.field.label, _vm.submission))+" is required\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }