//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import API from "~/api/api";
import format from "date-fns/format";

export default {
  data() {
    return {
      termsOfUse: null,
      privacyPolicy: null,
    };
  },
  props: {
    date: {
      required: true,
    },
  },
  methods: {
    getDate() {
      return format(new Date(this.date), "MMMM d, yyyy");
    },
    handleSubmit(data) {
      this.$store
        .$db()
        .model("profile")
        .updateAccount({ terms_accepted_at: new Date() })
        .then(() => this.$emit("success"))
        .catch(({ response }) => {
          if (response.status == 303 && response.data.message) {
            this.$emit("switch", true);
          } else {
            this.error = "User update failed, something went wrong!";
          }
        });
    },
    getPages() {
      return Promise.all([
        API.butterPages.get(this.$axios, "ln-terms-of-use"),
        API.butterPages.get(this.$axios, "privacy-policy"),
      ])
        .then((results) => {
          this.termsOfUse = results[0];
          this.privacyPolicy = results[1];
        })
        .catch((e) => {
          console.error(e);
          error({ message: "Error fetching data..." });

          return {
            loading: false,
          };
        });
    },
  },
  mounted() {
    this.getPages();
  },
};
