//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldBase from "@/mixins/field-base";
import { stateNameByValue } from "@/src/states";
import isMobile from "~/mixins/isMobile.js";

export default {
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  async mounted() {
    let packagesData = await this.$axios.get("/state_info/packages.json");
    this.allPackages = packagesData.data;
    if (process.client) {
      this.$store.dispatch("user/getUser");
    }
    this.setDefaults();
  },
  mixins: [fieldBase, isMobile],
  props: {
    submission: {
      required: true,
    },
    loading: {
      required: true,
    },
  },
  data() {
    return {
      isTableVisible: false,
      localValue: this.value,
      companyType: this.submission.entity_type
        ? this.submission.entity_type
        : "llc",
      stateID: this.submission.state_id
        ? parseInt(this.submission.state_id)
        : parseInt(this.$store.state.user.data.defaultState),
      allPackages: [],
      state: "",
      showTable: false,
    };
  },
  computed: {
    tableData() {
      let tableDataLocal = [
        {
          title: "Prepare and File Your " + this.readableEntity,
          hasSubContent: true,
          subContent: "",
          icon: require(`~/assets/icons/form/packages/icon-rocket.svg`),
          isInStarter: true,
          isInEssentials: false,
          isInPro: false,
          meta: {
            starter: "null",
            essential: null,
            pro: null,
          },
        },
        {
          title: "Everything in Starter",
          hasSubContent: true,
          subContent: "",
          icon: require(`~/assets/icons/form/packages/icon-rocket.svg`),
          isInStarter: false,
          isInEssentials: true,
          isInPro: false,
          meta: {
            starter: "null",
            essential: null,
            pro: null,
          },
        },
        {
          title: "Everything in Essentials",
          hasSubContent: true,
          subContent: "",
          icon: require(`~/assets/icons/form/packages/icon-rocket.svg`),
          isInStarter: false,
          isInEssentials: false,
          isInPro: true,
          meta: {
            starter: "null",
            essential: null,
            pro: null,
          },
        },
        {
          title: "Name Availability Search",
          hasSubContent: true,
          subContent:
            "There are strict rules about naming businesses. We conduct a search to ensure that your desired company name is compliant and available for use in your state.",
          icon: require(`~/assets/icons/form/packages/icon-search.svg`),
          isInStarter: true,
          isInEssentials: false,
          isInPro: false,
          meta: {
            starter: null,
            essential: null,
            pro: null,
          },
        },
        // {
        //   title: "FREE Registered Agent Service for a year!",
        //   hasSubContent: true,
        //   subContent:
        //     "All " +
        //     this.readableEntity +
        //     "s must have a registered agent available to the public during all regular business hours to receive your company’s official legal and government correspondence. For this reason, most business owners prefer to use a professional registered agent service like LegalNature’s to stay compliant with state law. All correspondence you receive will be immediately forwarded to you in both physical and electronic copy to ensure that you stay notified.",
        //   icon: require(`~/assets/icons/form/packages/icon-service-ra.svg`),
        //   isInStarter: false,
        //   isInEssentials: true,
        //   isInPro: true,
        //   meta: {
        //     starter: "+$125/ year",
        //     essential: "1st year Free",
        //     pro: "1st year Free",
        //   },
        // },

        {
          title: "EIN Business Tax ID",
          hasSubContent: true,
          subContent:
            "An Employer Identification Number (EIN) is like a social security number for your business. You will need this to keep your business and personal finances separate, communicate with the IRS, hire employees, and open a bank account.",
          icon: require(`~/assets/icons/form/packages/icon-ein.svg`),
          isInStarter: false,
          isInEssentials: true,
          isInPro: true,
          meta: {
            starter: "+$70",
            essential: null,
            pro: null,
          },
        },
        {
          title: "Rushed Filing",
          hasSubContent: true,
          subContent:
            "We prioritize your order by letting you skip to the front of our line.",
          icon: require(`~/assets/icons/form/packages/icon-fast.svg`),
          isInStarter: false,
          isInEssentials: true,
          isInPro: false,
          meta: {
            starter: "+$50",
            essential: null,
            pro: null,
          },
        },
        {
          title: this.LLCOAorBylaws,
          hasSubContent: true,
          subContent:
            "This is THE foundational document for every " +
            this.readableEntity +
            ". You will need it in order to define all of your company’s operating rules and to protect yourself and your business from many potential liabilities.",
          icon: require(`~/assets/icons/form/packages/icon-document.svg`),
          isInStarter: false,
          isInEssentials: true,
          isInPro: true,
          meta: {
            starter: "+$50",
            essential: null,
            pro: null,
          },
        },
        {
          title: "Banking Resolution",
          hasSubContent: true,
          subContent:
            "This document will be required by your bank in order to open a business bank account.",
          icon: require(`~/assets/icons/form/packages/icon-document.svg`),
          isInStarter: false,
          isInEssentials: true,
          isInPro: false,
          meta: {
            starter: "+$50",
            essential: null,
            pro: null,
          },
        },
        {
          title: "Business Tax Consulation",
          hasSubContent: true,
          subContent:
            "Receive a free consultation with 1-800ACCOUNTANT to help you plan your accounting strategy and save money.",
          icon: require(`~/assets/icons/form/packages/icon-dollarCircle.svg`),
          isInStarter: false,
          isInEssentials: true,
          isInPro: false,
          meta: {
            starter: null,
            essential: null,
            pro: null,
          },
        },
        {
          title: "Unlimited business documents for your LLC",
          hasSubContent: true,
          subContent:
            "Our 100+ legal and professional document templates use LegalNature’s automated technology engine to quickly create documents tailored to your specific needs. This helps you save big on legal fees while easily creating important documents for your business, including company resolutions, sales contracts, employee handbooks, confidentiality agreements, consulting agreements, cease and desist letters, and powers of attorney, just to name a few.",
          icon: require(`~/assets/icons/form/packages/icon-folder.svg`),
          isInStarter: false,
          isInEssentials: false,
          isInPro: true,
          meta: {
            starter: "+$84/ year",
            essential: null,
            pro: null,
          },
        },
        {
          title: "Expedited Filing",
          hasSubContent: true,
          subContent:
            "Need your business approved fast? LegalNature will use available means to file your documents faster with your state. We will communicate with the state on your behalf and work to quickly resolve any issues that may cause delays.",
          icon: require(`~/assets/icons/form/packages/icon-expedited.svg`),
          isInStarter: false,
          isInEssentials: false,
          isInPro: true,
          meta: {
            starter: "+$100",
            essential: "+$100",
            pro: null,
          },
        },
        {
          title: "Maintain SOS Compliance For Your LLC",
          hasSubContent: true,
          subContent:
            "Let us worry about keeping your business compliant with the state. Our Compliance Guard service includes all required annual SOS filings for one year, one amendment filing (should you need to update your information with the state), and all of the essential documents you might need for state compliance and record keeping, including meeting minutes, company resolutions, notices of meeting, and more.",
          icon: require(`~/assets/icons/form/packages/compliance-guard.svg`),
          isInStarter: false,
          isInEssentials: false,
          isInPro: true,
          meta: {
            starter: "+$125/ year",
            essential: "+$125/ year",
            pro: "1st year Free",
          },
        },
      ];
      return tableDataLocal;
    },
    faqs() {
      let data = {
        faq: [
          {
            table: {
              question: "Why do I need a Registered Agent?",
              answer:
                "All states require registered companies to provide a registered agent. This is a company or person that is always available during business hours in the state of formation to receive important notifications on your company’s behalf. As your registered agent, we will help you comply with state requirements and avoid fines. You will receive direct email notification as soon as any correspondence is received. You are always free to change your registered agent at any time and for any reason",
            },
          },
          {
            table: {
              question: "What is an EIN business tax ID?",
              answer:
                "An EIN, also known as a Federal Tax Identification Number, is a unique nine-digit number used to identify your business to the IRS. Think of it as a Social Security Number (SSN) but for your business. An EIN is sometimes required to open a business bank account, obtain a business loan, hire employees, and carry out other business-related activities.",
            },
          },
          {
            table: {
              question: "What is Compliance Guard?",
              answer:
                "Our Compliance Guard service includes all required annual SOS filings for one year, one amendment filing (should you need to update your information with the state), and all of the essential documents you might need for state compliance and record keeping, including meeting minutes, company resolutions, notices of meeting, and more.",
            },
          },
          {
            table: {
              question: "What is LegalNature+?",
              answer:
                "Our LegalNature+ subscription gives you access to over 100 professional documents that are easy to understand and create. The subscription includes access to many types of documents, such as estate planning, real estate documents, business management & human resources and documents for everyday legal matters and serious life events",
            },
          },
          {
            table: {
              question: "Can I deduct the formation fees?",
              answer:
                "In some cases, business formation costs are tax-deductible. You can find out more during your free tax consultation.",
            },
          },
          {
            table: {
              question: "Who is forming my business?",
              answer:
                "Our team of business formation experts will prepare and file all the necessary documents for your business.",
            },
          },
        ],
      };
      return data;
    },
    entityTitle() {
      if (this.companyType) {
        if (this.companyType === "llc") return "LLC";
        else if (this.companyType === "nonprofit") return "Nonprofit";
        else return "Corporation";
      } else return "formation";
    },

    readableEntity() {
      if (this.companyType === "llc") return "LLC";
      else if (this.companyType === "c_corp") return "C Corp";
      else if (this.companyType === "s_corp") return "S Corp";
      else if (this.companyType === "nonprofit") return "Nonprofit";
      return "LLC";
    },
    defaultState() {
      if (!this.submission.state_id)
        return parseInt(this.$store.state.user.data.defaultState);
      else return parseInt(this.submission.state_id);
    },
    companyTypes() {
      return [
        { text: "LLC", value: "llc", disabled: false },
        { text: "S Corp", value: "s_corp", disabled: false },
        { text: "C Corp", value: "c_corp", disabled: false },
        { text: "Nonprofit", value: "nonprofit", disabled: false },
      ];
    },
    selectedPackage() {
      if (this.stateID && this.companyType && this.allPackages.length > 0) {
        const singlePackage = [...this.allPackages].filter(
          (x) => x.state_id === parseInt(this.stateID)
        )[0];
        let localCompanyType = this.companyType;
        if (localCompanyType.indexOf("corp") != -1) {
          localCompanyType = "corp";
        }
        return singlePackage[localCompanyType];
      }
      return null;
    },
    LLCOAorBylaws() {
      if (this.companyType === "llc") return "LLC Operating Agreement";
      else return "Corporate Bylaws";
    },
    organizerOrIncorporator() {
      if (this.companyType === "llc") return "Statement of Organizer";
      else return "Statement of Incorporator";
    },
    directorsOrMembers() {
      if (this.companyType === "llc") return "members";
      else return "directors";
    },
    anOrA() {
      if (this.companyType === "llc") return "an";
      else return "a";
    },
  },
  methods: {
    openCompliance() {
      document.getElementById("questions").scrollIntoView();
      this.$root.$emit("bv::toggle::collapse", "accordion-2");
    },
    openPlus() {
      document.getElementById("questions").scrollIntoView();
      this.$root.$emit("bv::toggle::collapse", "accordion-3");
    },
    toggleTable() {
      this.showTable = !this.showTable;
    },
    handleScroll(event) {
      var el = document.querySelector(".plan-table__body");
      if (el) {
        var rect = el.getBoundingClientRect(),
          vWidth = window.innerWidth || doc.documentElement.clientWidth,
          vHeight = window.innerHeight || doc.documentElement.clientHeight,
          efp = function(x, y) {
            return document.elementFromPoint(x, y);
          };

        if (rect.top < -21) this.isTableVisible = true;
        else this.isTableVisible = false;

        if (
          rect.right < 0 ||
          rect.bottom < 0 ||
          rect.left > vWidth ||
          rect.top > vHeight
        )
          this.isTableVisible = false;
      }
    },
    stateNameByValue(stateId) {
      return stateNameByValue(stateId);
    },
    stateSelect(newState) {
      this.stateID = newState;
      this.state = this.stateNameByValue(newState);
      let localData = {
        entity_type: this.companyType,
        state: this.state,
        state_id: this.stateID,
      };
      this.$emit("updateData", localData);
    },
    choosePackage(selectedPackage) {
      let localData = {
        package_select: selectedPackage,
        entity_type: this.companyType,
        state: this.stateNameByValue(this.stateID),
        state_id: this.stateID,
      };
      this.$emit("updateData", localData);
      setTimeout(() => {
        this.$emit("nextPage");
      }, 100);
    },
    setDefaults() {
      let localData = {
        package_select: "Starter",
        entity_type: this.companyType,
        state: this.stateNameByValue(this.stateID),
        state_id: this.stateID,
      };
      this.$emit("updateData", localData);
    },
    shortenBusiness(str) {
      return str.replace("business", "bus.");
    },
  },
  watch: {
    companyType: {
      handler(newValue) {
        let localData = {
          entity_type: newValue,
        };
        this.$emit("updateData", localData);
      },
      deep: true,
    },
  },
};
