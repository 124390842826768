//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from "lodash";

export default {
  props: {
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    validationContext: {
      required: true,
    },
    submission: {
      required: false,
      default: "",
    },
  },
  data() {
    return { localValue: this.value };
  },
  methods: {
    addEntry() {
      this.localValue = cloneDeep(this.localValue).concat("");
    },
    removeEntry(index) {
      this.localValue = this.localValue.filter(
        (value, localIndex) => index !== localIndex
      );
    },
    priceForState(state) {
      let stateOpt = this.field.options.find((o) => o.value == state);
      if (stateOpt) {
        return stateOpt.price;
      }
    },
  },
  computed: {
    isTextArea() {
      return this.field.isTextareaMultiplier;
    },
    isSelect() {
      return this.field.isSelectMultiplier;
    },
    hasPricingOption() {
      return this.field.hasPricingOption;
    },
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
};
