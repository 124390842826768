//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  props: {
    currentItem: {
      type: Number,
      default: -1,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
    },
    shortRecords: {
      type: Array,
    },
    count: {
      type: Number,
    },
    allResultsUrl: {
      type: String,
    },
  },
  watch: {
    // showSearch: function(now, before) {
    //   setTimeout(() => {
    //     if (this.$refs.searchBar && this.$refs.searchBar.$el) {
    //       this.$refs.searchBar.$el.querySelectorAll("input")[0].focus();
    //     }
    //   }, 100);
    // },

    $route() {
      this.closeSearch();
    },
  },
  computed: {
    placeholder() {
      if (this.isMobile) return "Search";
      return "Search for documents, articles...";
    },
    categoryItemsRE() {
      return [
        {
          title: "Notice to Quit",
          link: "/categories/landlord-and-tenant/notice-to-quit",
        },
        {
          title: "Rental Agreement",
          link: "/categories/landlord-and-tenant/residential-rental-agreement",
        },
        {
          title: "Quit Claim Deed",
          link: "/categories/landlord-and-tenant/quit-claim-deed",
        },
        {
          title: "Purchase Agreement",
          link:
            "/categories/landlord-and-tenant/real-estate-purchase-agreement",
        },
      ];
    },
    categoryItemsEP() {
      return [
        {
          title: "Last Will and Testament",
          link: "/categories/estate-planning/last-will-and-testament",
        },
        {
          title: "Joint Revocable Living Trust",
          link: "/categories/estate-planning/joint-revocable-living-trust",
        },
        {
          title: "Living Will",
          link: "/categories/estate-planning/living-will",
        },
        {
          title: "Power of Attorney",
          link: "/categories/estate-planning/power-of-attorney",
        },
      ];
    },
    categoryItemsBF() {
      return [
        {
          title: "Articles of Organization",
          link: "/categories/business-formation/articles-of-organization",
        },
        {
          title: "Banking Resolution",
          link: "/categories/business-formation/banking-resolution",
        },
        {
          title: "Non-Disclosure Agreement",
          link: "/categories/human-resources/non-disclosure-agreement",
        },
        {
          title: "LLC Operating Agreement",
          link: "/categories/business-formation/llc-operating-agreement",
        },
      ];
    },
  },
  methods: {
    closeSearch() {
      this.$emit("closeSearch");
    },
    resultHover(index) {
      this.$emit("resultHover", index);
    },
  },
};
