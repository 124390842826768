//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    packageDetails: {
      required: true,
    },
    companyType: {
      required: true,
    },
    showTable: {
      default: false,
    },
    loading: {
      default: false,
    },
    LLCOAorBylaws: {
      default: false,
    },
  },
  methods: {
    shortenBusiness(str) {
      return str.replace("business", "biz.");
    },
    goToTable() {
      if (!this.showTable) {
        this.$emit("toggleTable");
      }
      this.$emit("openCompliance");
      setTimeout(() => {
        window.scroll({
          top: 2000,
          behavior: "smooth",
        });
      }, 300);
    },
  },
};
