//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      selectedPackage: "Essentials",
      showDescription: {},
      prices: { Starter: 49, Essentials: 169, Pro: 269 },
      isTableVisible: false,
      stickyCTA: true,
    };
  },

  props: {
    packageDetails: {
      required: true,
    },
    tableData: {
      required: true,
    },
    loading: {
      default: false,
    },
  },
  methods: {
    shortenBusiness(str) {
      return str.replace("business", "biz.");
    },
    hideShowDescription(index) {
      if (!this.showDescription[index])
        this.$set(this.showDescription, index, true);
      else this.$set(this.showDescription, index, !this.showDescription[index]);
    },
    isInactive(index) {
      if (
        this.selectedPackage === "Essentials" &&
        this.tableData[index].isInEssentials === false
      )
        return true;
      if (
        this.selectedPackage === "Starter" &&
        this.tableData[index].isInStarter === false
      )
        return true;
    },
  },
};
