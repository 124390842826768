import { render, staticRenderFns } from "./ln-form-packages.vue?vue&type=template&id=2e51cd32&scoped=true&"
import script from "./ln-form-packages.vue?vue&type=script&lang=js&"
export * from "./ln-form-packages.vue?vue&type=script&lang=js&"
import style0 from "./ln-form-packages.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ln-form-packages.vue?vue&type=style&index=1&id=2e51cd32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e51cd32",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PackagesPreviewBoxes: require('/usr/src/app/components/form-flow/custom-pages/packages-preview-boxes.vue').default,PackagesMobile: require('/usr/src/app/components/form-flow/custom-pages/packages-mobile.vue').default,Faqs: require('/usr/src/app/components/product-new/faqs.vue').default})
