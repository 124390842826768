var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(!_vm.isTextArea && !_vm.isSelect)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],class:[
      'tw-p-4 tw-block tw-w-full tw-rounded-md tw-border tw-border-silver tw-shadow-sm focus:tw-border-azure focus:tw-outline-none tw-leading-5',
      _vm.getValidationState(_vm.validationContext) === false
        ? 'tw-border-coral'
        : '' ],attrs:{"placeholder":_vm.placeholder},domProps:{"value":(_vm.localValue)},on:{"change":function($event){return _vm.$emit('update:value', _vm.localValue)},"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}}):_vm._e(),_vm._v(" "),(_vm.isTextArea)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],class:[
      'tw-p-4 tw-block tw-w-full tw-rounded-md tw-border tw-border-silver tw-shadow-sm focus:tw-border-azure focus:tw-outline-none tw-leading-5',
      _vm.getValidationState(_vm.validationContext) === false
        ? 'tw-border-coral'
        : '' ],attrs:{"placeholder":_vm.placeholder,"rows":"3"},domProps:{"value":(_vm.localValue)},on:{"change":function($event){return _vm.$emit('update:value', _vm.localValue)},"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}}):_vm._e(),_vm._v(" "),(_vm.isSelect)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"tw-p-4 tw-bg-white tw-block tw-w-full tw-rounded-md tw-border tw-border-silver tw-shadow-sm focus:tw-border-azure focus:tw-outline-none tw-leading-5",attrs:{"aria-describedby":("field-" + (_vm.field.name) + "-feedback"),"aria-placeholder":"Select an option"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.localValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.$emit('update:value', _vm.localValue)}]}},_vm._l((_vm.parsedFieldOptions),function(option){return _c('option',{domProps:{"value":option.value}},[_vm._v(_vm._s(option.text))])}),0):_vm._e(),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.getValidationState(_vm.validationContext) === false),expression:"getValidationState(validationContext) === false"}],staticClass:"tw-text-coral tw-mt-2",attrs:{"id":("field-" + (_vm.field.name) + "-feedback")}},[_vm._v("\n    "+_vm._s(_vm.parseLiquidString(_vm.field.label, _vm.submission))+" is required\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }